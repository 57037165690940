<template>
  <div class="chart">
    <div v-if="loading">
      <div class="overlay" />
      <div class="loader">
        <v-icon>mdi-dots-circle</v-icon>
      </div>
    </div>
    <!-- <div v-if="value.length > 0 && !value.every((r) => r == 0)"> -->
    <div v-if="value.length > 0">
      <apexchart
        ref="chart"
        :type="type"
        height="350"
        :options="chartOptions"
        :series="series"
      />
    </div>
    <div
      v-else
      class="no-data"
    >
      <h3>{{ title }}</h3>
      <div>No Data Available</div>
    </div>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    type: {
      type: String,
      default() {
        return "area";
      },
    },
    name: {
      type: String,
      default() {
        return "name";
      },
    },
    title: {
      type: String,
      default() {
        return "title";
      },
    },
    labels: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    xaxisType: {
      type: String,
      default() {
        return "datetime";
      },
    },
    xaxisText: {
      type: String,
      default() {
        return "xais text";
      },
    },
    yaxis: {
      type: Object,
      default() {
        return {};
      },
    },
    unit: {
      type: String,
      default() {
        return "";
      },
    },
    filter: {
      type: Array,
      default() {
        return [];
      },
    },
    dateFormat: {
      type: String,
      default() {
        return "DD-MMM-YYYY hh:mm:ss A";
      },
    },
    tooltipDateFormat: {
      type: String,
      default() {
        return "DD-MMM-YYYY hh:mm:ss A";
      },
    },
  },
  data() {
    return {
      options: {
        chart: {
          id: "area-datetime",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
          toolbar: {
            tools: {
              pan: false,
            },
            export: {
              csv: {
                filename: "",
                headerCategory: "cat",
              },
              svg: {
                filename: "",
              },
              png: {
                filename: "",
              },
            },
          },
        },
        title: {
          text: "Title",
          align: "left",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        yaxis: {},
        xaxis: {
          type: "datetime",
          title: {
            text: "",
          },
          // format: this.dateFormat,
          tickAmount: 5,
          labels: {
            datetimeUTC: false,
          },
        },
        tooltip: {
          x: {},
        },
        // fill: {
        //   type: "gradient",
        //   gradient: {
        //     shadeIntensity: 1,
        //     opacityFrom: 0.7,
        //     opacityTo: 0.9,
        //     stops: [0, 100],
        //   },
        // },
      },
    };
  },
  computed: {
    series() {
      // const sr = { name: "Desktops", data: this.value };
      const sr = { name: this.title, data: this.value };
      return [sr];
    },
    chartOptions() {
      const opt = JSON.parse(JSON.stringify(this.options));
      opt.chart.type = this.type;
      opt.title.text = this.title;

      // opt.xaxis.categories = this.labels;
      if (this.value.length > 0) {
        opt.xaxis.type = this.xaxisType;
        opt.xaxis.title.text = this.xaxisText;
        opt.yaxis = this.yaxis;
        opt.yaxis.labels = {
          formatter: (v) => (v ? `${parseInt(v)} ${this.unit}` : `0 ${this.unit}`),
        };
        // opt.yaxis.labels = {
        //   formatter: (v) => {
        //     if (v) {
        //       if (this.title == "Fuel Level") {
        //         return v + ` ${this.unit}`;
        //       } else {
        //         return parseInt(v) + ` ${this.unit}`;
        //       }
        //     } return "0" + ` ${this.unit}`;
        //   },
        // };

        const dFormat = this.dateFormat;
        opt.xaxis.labels = {
          formatter: function (value) {
            return moment(value).format(dFormat);
          },
        };
        const ttFormat = this.tooltipDateFormat;
        opt.tooltip.x = {
          formatter: function (value) {
            return moment(value).format(ttFormat);
          },
        };

        opt.tooltip.y = {
          formatter: function (value) {
            if (this.title === 'Fuel Level' && Number.isNaN(value)) {
              return "N/A"; // Show "N/A" for Fuel Level when value is 0
            } else {
              return `${value} ${this.unit}`;
            } // Default formatting for other cases
          }.bind(this),
        };

        // file Name
        let fName = opt.chart.toolbar.export;
        const d =
          this.filter.length > 0
            ? `| ${this.filter[0]} - ${this.filter[1]}`
            : "";
        const name = `${this.name}-telematic-report | ${this.title} ${d}`;
        fName.csv.filename = name;
        fName.svg.filename = name;
        fName.png.filename = name;
        fName.csv.headerCategory = this.xaxisText;
      }
      return opt;
    },
  },
  methods: {},
};
</script>
<style lang="sass" scoped>
.chart
    position: relative
    .overlay
        background: gray
        opacity: 15%
    .overlay, .loader
        position: absolute
        width: 100%
        height: 100%
        z-index: 5
        display: flex
        justify-content: center
        align-items: center

    .loader
        z-index: 9
        i
            font-size:  2.5rem
            animation: spin 2s infinite
@keyframes spin

    100%
        transform: rotate(360deg)




.no-data
  div
    color: gray
    padding: 1rem
    text-align: center
</style>